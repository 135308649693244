import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../components/layout'
import { graphql } from "gatsby"
import { Link } from 'gatsby'

export default  ({ data }) => {
    return (
        <Layout>
            <Helmet title="Impressum" />
            <div id="main">
                <Link to="/">
                    <div className="fa fa-arrow-left icon alt" aria-hidden="true"><span className="label">Facebook</span></div>
                </Link>
                <br></br>
                <br></br>
                <hr></hr>
                <section className="main">
                    <div
                        dangerouslySetInnerHTML={{ __html: data.allMarkdownRemark.edges[0].node.html }}
                    >
                    </div>
                </section>
            </div>
        </Layout>
    )
}



export const query = graphql`
  query Impressum {
    allMarkdownRemark( 
        filter: {
            frontmatter: {
                    slug: { eq: "impressum"}
            }
        })
        {
        edges{
          node {
            html
          }
        }
    }

  }
`